import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import styles from './_error.module.scss';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{`${t('PAGE_NOT_FOUND_TITLE')} — ${t('APP_TITLE')}`}</title>
      </Head>

      <div className={styles.errorPage}>
        <ReactSVG src="/images/puffin-glasses.svg" />

        <div>{t('PAGE_NOT_FOUND_TEXT')}</div>
      </div>
    </>
  );
};

export default PageNotFound;
